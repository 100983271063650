import classNames from 'classnames';
import React, { ComponentType, useMemo } from 'react';
import logo2 from '../assets/images/vive-le-voyage-logo-bildmarke.png';
import logo3 from '../assets/images/vive-le-voyage-logo-white.png';
import logo1 from '../assets/images/vive-le-voyage-logo.png';

interface Props {
  variant?: boolean;
  white?: boolean;
}

export const Logo: ComponentType<Props> = ({ variant, white }) => {
  const image = useMemo(() => {
    if (white) {
      return logo3;
    }
    if (variant) {
      return logo2;
    }
    return logo1;
  }, [variant, white]);
  return <img src={image} className={classNames('logo', { variant })} />;
};
