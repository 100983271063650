import classNames from 'classnames';
import React, { ComponentType, useCallback, useState } from 'react';
import iconFacebook from '../../assets/images/icon-facebook-white.png';
import iconInstagram from '../../assets/images/icon-instagram-white.png';
import { Logo } from '../logo';
import './navigation.scss';

interface Props {
  isHome?: boolean;
}

const scrollTo = (anchor) => {
  document.querySelector(anchor).scrollIntoView({
    behavior: 'smooth',
  });
};

export const Navigation: ComponentType<Props> = (props) => {
  const { isHome = false } = props;
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, []);
  return (
    <>
      <div
        id="nav-toggle"
        className={classNames({ open })}
        onClick={toggleOpen}
      />
      <div className={classNames('sidebar', { open })}>
        <Logo white />
        <ul>
          <li>
            {' '}
            <a
              id="home"
              className="menu-item"
              href="/"
              onClick={(e) => {
                if (isHome) {
                  setOpen(false);
                  e.preventDefault();
                  window.scrollTo(0, 0);
                }
              }}
            >
              Home
            </a>
          </li>
          <li>
            <a
              className="menu-item"
              onClick={(e) => {
                if (isHome) {
                  setOpen(false);
                  e.preventDefault();
                  scrollTo('#mein-angebot');
                }
              }}
              href="/#mein-angebot"
            >
              Mein Angebot
            </a>
          </li>
          <li>
            <a
              className="menu-item"
              onClick={(e) => {
                if (isHome) {
                  setOpen(false);
                  e.preventDefault();
                  scrollTo('#ueber-mich');
                }
              }}
              href="/#ueber-mich"
            >
              Über mich
            </a>
          </li>
          <li>
            <a
              className="menu-item"
              onClick={(e) => {
                if (isHome) {
                  setOpen(false);
                  e.preventDefault();
                  scrollTo('#kundenstimmen');
                }
              }}
              href="/#kundenstimmen"
            >
              Kundenstimmen
            </a>
          </li>
          <li>
            <a
              className="menu-item"
              onClick={(e) => {
                if (isHome) {
                  setOpen(false);
                  e.preventDefault();
                  scrollTo('#my-roadtrip');
                }
              }}
              href="/#my-roadtrip"
            >
              myRoadtrip
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              id="impressum"
              className="menu-item small mt-5"
              href="/impressum"
            >
              Impressum
            </a>
          </li>
          <li>
            <a id="datenschutz" className="menu-item small" href="/datenschutz">
              Datenschutz
            </a>
          </li>
        </ul>
        <ul className="social-links horizontal">
          <li>
            <a target="_blank" href="https://www.facebook.com/ViveleVoyage.de">
              <img src={iconFacebook} />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://instagram.com/vive.levoyage?igshid=4sem4n6jihxr"
            >
              <img src={iconInstagram} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
