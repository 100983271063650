import React, { ComponentType, ReactNode } from 'react';
import { Footer } from './footer';

interface PageProps {
  header?: ReactNode;
}
export const Page: ComponentType<PageProps> = (props) => {
  const { children, header } = props;

  return (
    <div className="container page p-0">
      {header}
      {children}
      <Footer />
    </div>
  );
};
