import React, { ComponentType } from 'react';
import iconFacebook from '../assets/images/icon-facebook.png';
import iconInstagram from '../assets/images/icon-instagram.png';
interface Props {}
export const Footer: ComponentType<Props> = ({}) => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-sm-6">
            <ul className="social-links horizontal">
              <li>
                <a
                  target="_blank"
                  href="https://www.facebook.com/ViveleVoyage.de"
                >
                  <img src={iconFacebook} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://instagram.com/vive.levoyage?igshid=4sem4n6jihxr"
                >
                  <img src={iconInstagram} />
                </a>
              </li>
            </ul>

            <p>© {new Date().getFullYear()}</p>
          </div>
          <div className="col col-12 col-sm-6 text-right">
            <ul className="">
              <li>
                <a href="/impressum">Impressum</a>
              </li>
              <li>
                <a href="/datenschutz">Datenschutz</a>
              </li>
              <li>
                <a href="mailto:info@vivelevoyage.de">info@vivelevoyage.de</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
